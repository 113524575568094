/**
 *
 *
 *
 *
 *
 *
 */
export const { fragment } = window.o_util ?? {};
export const { deserialize, get, push, remove, serialize, set } = fragment ?? {};
