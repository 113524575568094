import { AS } from "@gr-common/body/namespaces";
import { AsyncNamespace } from "../nexus/namespace/namespace.types";
import { ApmRum } from "../../exports/apm";

declare global {
  interface NexusInterfaces {
    /**
 *
 *
 */
    debug: AsyncNamespace<{
      /*                      */
      sendLog: ApmRum["sendCustomError"];
    }>;
  }
}

/*                                                   */
otto.debug.assignNamespace(
  {
    /*                                                            */
    sendLog: (namespace, data) => {
      /*                                                */
      /*                                          */
      AS.RUM.sendCustomRequestOld(namespace, data);
      return otto.apm.sendCustomError(namespace, data);
    },
  },
  { lock: import.meta.env.VITE_OTTO_IS_TESTING_BUNDLE !== "true" },
);
