/**
 *
 *
 *
 *
 *
 *
 *
 */
import type { PreloadEvents } from "@otto-ec/preload-polyfill";
import type { QBusEventMap } from "../lib/event-q-bus/q-bus/index.js";
import type { EventLoaderEvents } from "./event-loader.js";

export type {
  QBus,
  QBusEventEnum,
  QBusEventMap,
  QBusOptions,
} from "../lib/event-q-bus/q-bus/index.js";

export type { QBusTopic } from "../lib/event-q-bus/utils/index.js";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export type GlobalEvents = QBusEventMap<EventLoaderEvents & PreloadEvents>;

export const { events, eventQBus } = window.o_global ?? {};
