/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export const { event } = window.o_util ?? {};
export const { delegate, stop, undelegate, whichTransitionEndEvent } = event ?? {};
